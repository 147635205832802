.App {
  text-align: center;
}

.uploaders {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.image-comparator {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.image-container {
  margin: 0 10px;
}

.score-board {
  margin-bottom: 20px;
}